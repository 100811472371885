<template>
  <div class="container" id="form-mail" style="max-height: max-content;">
    <form ref="entryForm" class="form_container" @submit.prevent="sendEmail" novalidate>
      <div class="form-row">
        <label>{{ dataMail.name }}</label>
        <div class="input-data">
          <input ref="refInput" type="text" v-model="name" :placeholder="dataMail.name" @blur="onBlur" :class="{
            errorBorder: errors.name,
          }" />
          <p class="danger" v-if="errors.name">{{ errors.name }}</p>
        </div>
      </div>
      <div class="form-row">
        <label>{{ dataMail.email }}</label>
        <div class="input-data">
          <input ref="emailInput" v-model="email" @change="onBlur" :placeholder="dataMail.email" :class="{
            errorBorder: errors.email,
          }" />
          <p class="danger" v-if="errors.email">{{ errors.email }}</p>
        </div>
      </div>
      <div class="group_row">
        <div class="form-row">
          <label>{{ dataMail.birthday }}</label>
          <div class="input-data">
            <input type="date" v-model="birthday" @blur="onBlur" :placeholder="dataMail.birthday" :class="{
              errorBorder: errors.birthday,
            }" />
            <p class="danger" v-if="errors.birthday">{{ errors.birthday }}</p>
          </div>
        </div>
        <div class="form-row">
          <label>{{ dataMail.phone }}</label>
          <div class="input-data">
            <input type="text" v-model="phone" @blur="onBlur" :placeholder="dataMail.phone" :class="{
              errorBorder: errors.phone,
            }" />
            <p class="danger" v-if="errors.phone">{{ errors.phone }}</p>
          </div>
        </div>
      </div>
      <div class="form-row">
        <label>{{ dataMail.skills }}</label>
        <div class="input-data" :style="{
          height: 'unset',
        }">
          <div class="multiselect__custom">
            <multiselect v-model="selectedSkills" :options="skills" :multiple="true" :searchable="false"
              :close-on-select="false" :show-labels="false" placeholder="Skills" @select="onBlur" label="name"
              track-by="name" style="width: 100%" :class="{
                errorBorder: errors.selectedSkills,
              }">
            </multiselect>
            <input :value="selectedSkills" style="
                width: 0;
                height: 0;
                padding: 0;
                border-color: transparent;
                outline: transparent;
                position: absolute;
                left: 50%;
                transform: translateY(50%);
              " />
            <p class="danger" v-if="errors.selectedSkills">
              {{ errors.selectedSkills }}
            </p>
          </div>
        </div>
      </div>
      <div class="form-row">
        <label>{{ dataMail.message }}</label>
        <div class="input-data textarea">
          <textarea rows="4" cols="50" v-model="yourMessage" :placeholder="dataMail.message"></textarea>
          <br />
          <br />
        </div>
      </div>
      <div class="form-row">
        <div class="input-data">
          <div class="btn-cv" @click="onButtonClick">
            {{
              this.files
                ? this.files.name.length > 9
                  ? this.files.name.slice(0, 10) + '...'
                  : this.files.name
                : this.upload
            }}
          </div>
          <div v-if="this.files" class="btn-close" @click="clearFiles">+</div>
          <input ref="uploader" class="d-none" type="file" accept=".pdf,.doc,.docx" @change="onFileChanged"
            :value="null" :class="{
              errorBorder: errors.files,
            }" />
          <p class="danger" v-if="errors.files">{{ errors.files }}</p>
        </div>
      </div>
      <div class="content-cv">{{ dataMail.content_cv }}</div>

      <div class="form-row">
        <div class="input-data">
          <div class="btn-cv" @click="onButtonOtherClick">
            {{ dataMail.other_file }}
          </div>
          <input ref="otherUploader" class="d-none" type="file" multiple @change="onFilesChange" />
        </div>
        <div v-if="otherFiles?.length > 0" class="list-other-file">
          <span>{{ dataMail.other_file_list }}</span>
          <ul>
            <li v-for="(file, index) in otherFiles" :key="index"><span>{{ file.name }}</span><span @click="removeFile(file.name)" class="btn-delete-file">[{{ dataMail.delete }}]</span></li>
          </ul>
        </div>
      </div>
      <div class="form-row submit-btn">
        <button :disabled="loadingSubmit" type="submit" class="btn-submit">
          {{ loadingSubmit ? 'Loading' : dataMail.submit }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import { mapState } from 'vuex';
import Multiselect from 'vue-multiselect';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import 'vue2-datepicker/index.css';

const TRACKING_KEY = process.env.VUE_APP_TRACKING_KEY;
const HRM_URL = process.env.VUE_APP_API_URL;

Vue.use(Toast, {
  timeout: 1000,
});

export default {
  name: 'FormMail',
  data() {
    return {
      dataFormMail: this.$t('contact').dataFormMail,
      job_id: '',
      isFormat: false,
      loading: false,
      loadingSubmit: false,
      dialog: false,
      isCloseIcon: false,
      upload: this.dataMail.upload_cv,
      router: null,
      files: null,
      otherFiles: [],
      success: '',
      result: '',
      selectedFile: null,
      valid: false,
      name: '',
      phone: '',
      website: '',
      yourMessage: '',
      email: '',
      nameRules: [],
      emailRules: [],
      phoneRules: [],
      checkRouter: this.$route.path,
      birthday: '',
      selectedSkills: null,
      errors: {},
      touched: {
        name: false,
        email: false,
        phone: false,
      },
      touchedAll: false,
    };
  },
  props: {
    dataMail: {
      type: Object,
    },
    skills: Array,
    nameJob: String,
  },
  created() {
    this.router = this.$route.name;
    if (this.router === 'Contact') {
      this.upload = this.dataMail.upload_file;
    } else this.upload = this.dataMail.upload_cv;
  },
  watch: {
    lang() {
      this.dataFormMail = this.$t('contact').dataFormMail;
      this.router = this.$route.name;
      if (this.router === 'Contact') {
        this.upload = this.dataMail.upload_file;
      } else this.upload = this.dataMail.upload_cv;
    },
    name() {
      this.nameRules = [];
    },
    phone() {
      this.phoneRules = [];
    },
    email() {
      this.emailRules = [];
    },
  },
  components: {
    Multiselect,
  },
  methods: {
    async sendEmail() {
      this.touchedAll = true;
      this.loading = true;
      this.isFormat = false;
      this.checkValidate();

      const skill = this.selectedSkills?.map((i) => i.id) || [];

      if (Object.values(this.errors).length > 0) return;

      this.loadingSubmit = true;
      const formData = new FormData();
      formData.append('jobId', this.job_id);
      formData.append('fullName', this.name);
      formData.append('email', this.email);
      formData.append('message', this.yourMessage);
      formData.append('phone', this.phone);
      skill?.forEach((id) => {
        formData.append('listSkillId', id);
      });
      this.otherFiles?.forEach((file) => {
        formData.append('uploadFiles', file);
      });
      formData.append('dob', this.birthday);

      if (this.files) {
        formData.append('uploadFile', this.files);
      }
      try {
        await axios.post(`${HRM_URL}/applications`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        await axios.post('https://alink.wiki/api/v1/events', {
          project_token: TRACKING_KEY,
          category: `job_detail-${this.nameJob}-${this.job_id}`,
          action: 'click_btn_submit_popup',
        });
        this.$toast.success('Bạn đã ứng tuyển thành công', {
          timeout: 1000,
          position: 'bottom-right',
        });
      } catch (err) {
        this.$toast.error(err.message, {
          timeout: 1000,
          position: 'bottom-right',
        });
      }
      this.loadingSubmit = false;
      this.name = '';
      this.email = '';
      this.website = '';
      this.yourMessage = '';
      this.phone = '';
      this.files = null;
      this.birthday = '';
      this.selectedSkills = [];
      this.otherFiles = [];
    },
    validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(String(email).toLowerCase()))
        return this.dataMail.required.error_email;
    },
    validatePhone(phone) {
      if (!/^\d+$/.test(phone)) return this.dataMail.required.error_number;
    },
    onBlur() {
      if (this.touchedAll) this.checkValidate();
    },
    checkValidate() {
      this.errors = {};
      if (!this.name) {
        this.errors.name = this.dataFormMail.required.name;
      } else if (
        !/^[a-zàáảãạăắằẳẵặâấầẩẫậèéẻẽẹêềếểễệđìíỉĩịòóỏõọôốồổỗộơớờởỡợùúủũụưứừửữựỳýỷỹỵ\s]+$/i.test(
          this.name
        )
      ) {
        this.errors.name = this.dataFormMail.required.error_name;
      }

      if (!this.email) {
        this.errors.email = this.dataFormMail.required.email;
        // eslint-disable-next-line no-useless-escape
      } else if (
        !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.email)
      ) {
        this.errors.email = this.dataFormMail.required.error_email;
      }

      if (!this.phone) {
        this.errors.phone = this.dataFormMail.required.phone;
      } else if (!/^\d{10,12}$/.test(this.phone)) {
        this.errors.phone = this.dataFormMail.required.error_number;
      }

      if (!this.birthday) {
        this.errors.birthday = this.dataFormMail.required.birthday;
      } else if (new Date(this.birthday) > new Date()) {
        this.errors.birthday = this.dataFormMail.required.error_birthday;
      }

      const skill = this.selectedSkills?.map((i) => i.id) || [];
      if (skill.length === 0) {
        this.errors.selectedSkills = this.dataFormMail.required.skill;
      }
      if (!this.files) {
        this.errors.files = this.dataFormMail.required.cv;
      }
    },
    clearFiles() {
      this.files = null;
      this.$refs.uploader.value = null;
    },
    onButtonClick() {
      if (this.isCloseIcon) return;
      this.$refs.uploader.click();
    },
    onButtonOtherClick() {
      this.$refs.otherUploader.click();
    },
    removeFile(name) {
      console.log(this.otherFiles)
      this.otherFiles = this.otherFiles.filter((i)=>i.name != name) 
    },
    checkFileSize(file) {
      const maxSize = 10 * 1024 * 1024;
      return file.size < maxSize;
    },
    onFilesChange(event) {
      this.otherFiles = [ ...this.otherFiles, ...Array.from(event.target.files)];
    },
    onFileChanged(e) {
      this.files = e.target.files[0];
      if (!this.files) return;
      if (!this.checkFileSize(this.files)) {
        this.files = null;
        this.$toast.error(this.dataFormMail.file_size, {
          timeout: 3000,
          position: 'bottom-right',
        });
        return;
      }
      this.$toast.success('File đã được tải thành công', {
        timeout: 1000,
        position: 'bottom-right',
      });
      this.checkValidate();
      this.$refs.uploader.value = null;
    },
    validateText(text, result) {
      if (!text.trim()) {
        return result;
      }
    },
    checkFile(file) {
      const format = ['.pdf', '.doc', '.docx'];
      for (let i = 0; i < format.length; i++) {
        if (file.name.toLowerCase().indexOf(format[i]) > -1) {
          return true;
        }
      }
      return false;
    },
    onCloseIcon() {
      this.isCloseIcon = true;
      this.files = null;
      setTimeout(() => {
        this.isCloseIcon = false;
      }, 500);
    },
  },
  mounted() {
    this.job_id = this.$route.params.id;
    // const firebaseConfig = {
    //   apiKey: 'AIzaSyDvF206pThe30VaIou_Cc2YLiHh-GzHYIY',
    //   authDomain: 'tgl-solution-product.firebaseapp.com',
    //   projectId: 'tgl-solution-product',
    //   storageBucket: 'tgl-solution-product.appspot.com',
    //   messagingSenderId: '671677517840',
    //   appId: '1:671677517840:web:8b5e5e5b13a3d51b0ffa95',
    //   measurementId: 'G-W0LGN0XYQN'
    // };
    // firebase.initializeApp(firebaseConfig);
  },
  computed: {
    ...mapState({
      lang: (state) => state.langs.lang,
    }),
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
.container {
  padding: 8px;
  max-height: calc(100vh - 50px);
  overflow-y: scroll;
}

*::-webkit-scrollbar {
  display: none;
}

@media (max-width: 1024px) {
  .container {
    padding: 0px;
    max-height: max-content;
    overflow: unset;
  }

  .group_row {
    flex-direction: column;
    /* gap: 12px !important; */
  }
}

.container .text {
  text-align: center;
  font-size: 41px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  background: -webkit-linear-gradient(right,
      #56d8e4,
      #9f01ea,
      #56d8e4,
      #9f01ea);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.container form {
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.container form .form-row {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: -webkit-fill-available;
  .list-other-file{
    font-size: 14px;
    ul{
      padding: 0;
    }
    li{
      display: flex;
      flex-wrap: wrap;
    }
    .btn-delete-file{
      color: red;
      margin-left: 8px;
      cursor: pointer;
    }
  }
}

.group_row {
  display: flex;
  gap: 24px;
  flex: 1;
}

form .form-row .input-data {
  width: 100%;
  height: 40px;
  position: relative;

  .btn-close {
    position: absolute;
    top: 4px;
    right: 10px;
    font-size: 32px;
    transform: rotate(45deg);
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 99px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
      transition: all 0.3s linear;
    }
  }
}

.mx-datepicker {
  .mx-input-wrapper {
    height: 40px !important;
  }
}

form .form-row .input-data .danger,
.multiselect__custom .danger {
  color: #f14668;
  font-size: 0.75rem;
  margin-top: 0.25rem;
  display: block;
}

form .form-row .textarea {
  height: 120px;
}

.input-data input,
.textarea textarea,
input[type='date'] {
  -webkit-appearance: none;
  font-size: 14px;
  line-height: 14px;
  display: block;
  width: 100%;
  height: 100%;
  padding: 10px;
  border: 1px solid #b2b2b2;
  border-radius: 6px;
  outline: none;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
  color: var(--brown-text);
  background: #fff;

  &.errorBorder {
    border-color: red;
  }
}

.input-data input:focus~label,
.textarea textarea:focus~label,
.input-data input:not(:placeholder-shown)~label,
.textarea textarea:not(:placeholder-shown)~label {
  transform: translateY(-20px);
  font-size: 14px;
  color: var(--blue-text);
}

.textarea textarea {
  resize: none;
  padding-top: 10px;
}

.input-data label {
  position: absolute;
  pointer-events: none;
  bottom: 0px;
  background: #fff;
  transition: all 0.3s ease;
  font-size: 14px;
  padding-right: 8px;
}

.textarea label {
  width: 100%;
  background: #fff;
}

label {
  margin-bottom: 0;
}

.input-data .underline {
  position: absolute;
  bottom: 0px;
  height: 2px;
  width: 100%;
}

.input-data .underline:before {
  position: absolute;
  content: '';
  height: 2px;
  width: 100%;
  background: var(--blue-text);
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.3s ease;
}

.input-data input:focus,
.multiselect:focus,
.textarea textarea:focus {
  transform: scale(1);
  border-color: #6ba7fa;
  border-right-width: 1px;
  box-shadow: 0 0 0 2px rgba(64, 129, 236, 0.2);
  outline: 0;
  border-radius: 6px;
}

.submit-btn .input-data {
  overflow: hidden;
  height: 45px !important;
  width: 25% !important;
}

.submit-btn .input-data .inner {
  height: 100%;
  width: 300%;
  position: absolute;
  left: -100%;
  background: -webkit-linear-gradient(right,
      #56d8e4,
      #9f01ea,
      #56d8e4,
      #9f01ea);
  transition: all 0.4s;
}

.submit-btn .input-data:hover .inner {
  left: 0;
}

.submit-btn .input-data input {
  background: none;
  border: none;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  position: relative;
  z-index: 2;
}

@media (max-width: 700px) {
  .container .text {
    font-size: 30px;
  }

  .container form {
    padding: 10px 0 0 0;
  }

  .container form .form-row {
    display: block;
  }

  form .form-row .input-data {
    margin: 0 !important;
  }

  .submit-btn .input-data {
    width: 40% !important;
  }
}

.theme--light.v-label {
  color: var(--blue-text);
}

.v-btn {
  letter-spacing: normal;
  text-transform: none;
}

.submit {
  width: 160px;
  border-radius: 40px;
  height: 46px !important;
}

.v-text-field input {
  font-size: 18px;
}

.v-text-field--outlined>fieldset {
  border-color: #a7a7a7;
}

.form {
  margin-bottom: 0px;
}

.btn-submit,
.btn-cv {
  width: 100%;
  padding: 8px 0 !important;
  border-radius: 20px;
  background-color: transparent !important;
  border: 1px solid var(--blue-text);
  color: var(--blue-text);
  font-weight: 600;
  text-align: center;
  cursor: pointer;
}

.btn-cv:active,
.btn-submit:active {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  outline: none;
}

.content-cv {
  margin-top: 0px;
  font-weight: 400;
  font-size: 12px;
  color: var(--brown-text);
}

.btn-submit {
  font-size: 14px;
  font-weight: 700;
  height: 48px !important;
  text-align: center;
  background-color: #002a9c !important;
  color: #fff;
  border: none;
}

@media (max-width: 820px) {
  .title {
    font-size: 24px !important;
  }
}

@media (max-width: 720px) {
  .form.email {
    margin-top: -30px;
  }
}

.input-file {
  width: 100px;
}

.v-input__icon--append .v-icon {
  color: purple;
}

.v-text-field.v-input__control.v-input__slot:hover {
  cursor: pointer !important;
}

@media (max-width: 320px) {
  .text-button {
    font-size: 10px !important;
  }
}

.error-format {
  color: #ff5252;
  font-size: 12px;
  font-weight: 450;
}

.multiselect {
  &.errorBorder {
    border-bottom-width: 2px;
    border-color: red;
  }
}
</style>

<style>
.mx-btn:hover {
  color: var(--orange);
  border-color: var(--orange);
}

.mx-table-date .today {
  color: var(--orange);
}

.mx-calendar-content .cell.active {
  background-color: var(--orange);
}

.mx-input {
  height: 40px;
}

.errorBorder .mx-input {
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
  border-color: red;
}

.multiselect__custom {
  width: 100%;
}

.multiselect__tag {
  margin-bottom: 0;
  background-color: #fc7735 !important;
}

.multiselect__tag-icon::after {
  color: white;
}

.multiselect__tag-icon:hover {
  background-color: transparent !important;
}

.multiselect {
  border-radius: 6px;
  border: 1px solid #b2b2b2;
}

.multiselect__tags {
  display: flex;
  align-items: center;
  padding: 8px !important;
  border: none;
}

.multiselect__tags-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  margin-right: 24px;
  overflow: visible;
}

.multiselect__placeholder {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  color: var(--brown-text);
  font-size: 14px;
}

.multiselect__tag {
  margin: 0;
}

.multiselect__option--highlight {
  background: var(--orange);
}
</style>
